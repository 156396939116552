import React from "react";
import Helmet from "react-helmet";
import { graphql, Link } from "gatsby";
import { Grid, List, Card, Segment, Image, Button, Icon, Header } from "semantic-ui-react";
import {
  PageLayout,
  HeroElement,
  ImageCollection,
  Embed,
} from "@fishrmn/fishrmn-components";

import Layout from "../components/Layout";

export default class extends React.PureComponent {
  render() {
    const businessData = this.props.data.allFisherman.edges[0].node;
    const imageData = this.props.data.allFishermanImages.edges[0].node;
    const data = { ...businessData, ...imageData };

    return (
      <Layout>
        <Helmet>
          <title>{data.businessName} | Rewards</title>
          <meta name="description" content="" />
        </Helmet>
        <div className="rewards-page">
          <PageLayout
            hero={
              <HeroElement
                header=""
                tagline={""}
                images={["https://fisherman.gumlet.io/public/eggandbird/Rewards-min.jpg"]}
                showMap={false}
                ctas={[]}
                ctaInverted={true}
                ctaColor={"white"}
                containerColor={"primary"}
                textColor={"white"}
                showMultiple={true}
                overlay={true}
                fullWidth={true}
                parallax={true}
                containerAs={"none"}
                height={500}
                gutter={false}
              />
            }
          >

            {/* <Grid
              stackable
              className="component-section-containr rewards-section"
              textAlign="center"
              verticalAlign="middle"
              >
                <Grid.Column width="10">
                  <Grid
                    stackable
                    columns="2"
                    textAlign="center"
                    verticalAlign="middle"
                    centered={true}
                  >
                    <Grid.Row>
                      <Grid.Column largeScreen="8" wideScreen="8" textAlign="center" tablet="16">
                        <Segment inverted padded="very" className="custom-card">
                          <h1>MAKE EVERY VISIT MORE REWARDING!</h1>
                          <p>Earn and redeem rewards, receive exclusive offers, and get 50 Bonus Eggs when you sign up!</p>
                          <Button>JOIN NOW</Button>
                        </Segment>
                      </Grid.Column>
                      <Grid.Column largeScreen="7" wideScreen="7" tablet="16">
                        <Image src="https://fisherman.gumlet.io/public/eggandbird/093-22_enb_rewards_lockup.png" 
                        centered size="medium"/>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid
                      stackable
                      columns="2"
                      textAlign="center"
                      verticalAlign="middle" 
                      >
                        <Grid.Column>
                          <div style={{color:"#000"}}>Sign In</div>  
                          <Button color="black">LOG IN</Button>
                        </Grid.Column>
                        <Grid.Column>
                          <div style={{color:"#000"}}>Register</div>  
                          <Button color="white">CREATE ACCOUNT</Button>
                        </Grid.Column>
                        <Grid.Column width="16" >
                          <p style={{color:"#000"}}>Have a gift card? <span style={{textDecoration:'underline'}}>Register it as your rewards card.</span></p>
                        </Grid.Column>
                      </Grid>
                    </Grid.Row>
                  </Grid>
                </Grid.Column>
            </Grid> */}

            <Grid
              stackable
              className="component-section-containr rewards-section"
              textAlign="center"
              verticalAlign="middle"
              >
                <Grid.Column width="16">
                  <div style={{margin:'1.25em 0'}}>
                    <Image 
                      src="https://fisherman.gumlet.io/public/eggandbird/093-22_enb_rewards_lockup.png" 
                      centered size="medium"
                    />
                  </div>
                  <p>Earn and redeem rewards, receive exclusive offers, and get 50 Bonus Points when you sign up!</p>
                  
                  <div>
                  <Button color="black" as="a" href="https://eggnbird.myguestaccount.com/guest/accountlogin" target="_blank">LOG IN</Button>
                  <Button color="white" as="a" href="https://eggnbird.myguestaccount.com/guest/enroll?card-template=ktYylJhNj2k&template=1" target="_blank">SIGN UP</Button>
                  </div>

                  <p style={{color:"#000", margin:'1.5em 0', fontSize:'14px'}}>Have a gift card? <a href="https://eggnbird.myguestaccount.com/guest/register" target="_blank" style={{textDecoration:'underline', color:"#000"}}>Register it as your rewards card.</a></p>
                </Grid.Column>
            </Grid>
              

            <Grid
            stackable
            className="component-section-containr rewards-section"
            textAlign="center"
            verticalAlign="middle"
            >
              {/* <Grid.Column width="10" only="tablet">
                <h2 style={{color:"#000"}}>HOW IT WORKS</h2>
              
                <div style={{background:'#1b1c1d', display:'flex', alignItems:"center"}}>
                  <div>
                    <Image src="https://fisherman.gumlet.io/public/eggandbird/Welcome_to_ENB_Rewards.png" size="large"/>
                  </div>
                  <div>
                  <Segment inverted padded="very">
                    <h1>EVERY $1 SPENT = 2 EGGS</h1>
                    <div  style={{margin:"1em"}}>
                    Register today to start earning delicious rewards!
                    </div>
                    <Button>JOIN NOW</Button>
                  </Segment>
                  </div>
                </div>
              </Grid.Column> */}

              <div className="show-large-screen">
                <Grid.Column width="10">
                  <h2 style={{color:"#000"}}>HOW IT WORKS</h2>
                
                  <div style={{background:'#1b1c1d', display:'flex', alignItems:"center"}}>
                    <div>
                      <Image src="https://fisherman.gumlet.io/public/eggandbird/Welcome_to_ENB_Rewards.png" size="large"/>
                    </div>
                    <div>
                    <Segment inverted padded="very">
                      <h1 className="how-it-works-title">EVERY $1 SPENT = 2 Points</h1>
                      <div  style={{margin:"1em"}}>
                      Register today to start earning delicious rewards!
                      </div>
                      <Button as="a" target="_blank" href="https://eggnbird.myguestaccount.com/guest/enroll?card-template=ktYylJhNj2k&template=1">JOIN NOW</Button>
                    </Segment>
                    </div>
                  </div>
                </Grid.Column>
              </div>

              <div className="show-tablet-mobile">
                <Grid.Column width="10" textAlign="center">
                  <h2 style={{color:"#000"}}>HOW IT WORKS</h2>
                
                  <div style={{background:'#1b1c1d', alignItems:"center", maxWidth:"420px", margin:'0 auto'}}>
                    <div>
                      <Image src="https://fisherman.gumlet.io/public/eggandbird/Welcome_to_ENB_Rewards.png" size="large"/>
                    </div>
                    <div>
                    <Segment inverted padded="very">
                      <h1 className="how-it-works-title">EVERY $1 SPENT = 2 Points</h1>
                      <div  style={{margin:"1em"}}>
                      Register today to start earning delicious rewards!
                      </div>
                      <Button as="a" target="_blank" href="https://eggnbird.myguestaccount.com/guest/enroll?card-template=ktYylJhNj2k&template=1">JOIN NOW</Button>
                    </Segment>
                    </div>
                  </div>
                </Grid.Column>
              </div>
            </Grid>

            <Grid  
            stackable
            className="component-section-containr rewards-section"
            textAlign="center"
            verticalAlign="middle"
            >
              <Grid.Column width="10">
                <Grid
                divided
                stackable
                columns="3"
                textAlign="center"
                verticalAlign="middle"
                >
                  <Grid.Row>
                    <Grid.Column>
                      <Grid stackable
                        textAlign="center"
                        verticalAlign="top">
                        <Grid.Row>
                          <Grid.Column width="16">
                          <Image src="https://fisherman.gumlet.io/public/eggandbird/63c9a08835df6.png" centered size="tiny"/>
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                          <Grid.Column width="16">
                            <Header as="h3" color="black">
                              Earn 100 Points, Get $5
                            </Header>
                            <div>
                              Each time you earn 100 Points, we'll automatically send you a $5 reward
                            </div>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Grid.Column>
                    <Grid.Column>
                      <Grid tackable
            textAlign="center"
            verticalAlign="top">
                        <Grid.Row>
                          <Grid.Column width="16">
                            <Image src="https://fisherman.gumlet.io/public/eggandbird/63c9a08cb33aa.png" centered size="tiny"/>
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                          <Grid.Column width="16">
                            <Header as="h3" color="black">
                              Birthday Treat
                            </Header>
                            <div>
                              Let us help you celebrate. Enjoy a FREE birthday treat — on us!
                            </div>
                            <br />
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Grid.Column>
                    <Grid.Column>
                      <Grid tackable
                        textAlign="center"
                        verticalAlign="top">
                        <Grid.Row>
                          <Grid.Column width="16">
                            <Image src="https://fisherman.gumlet.io/public/eggandbird/63c9a090671f2.png" centered size="tiny"/>
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                          <Grid.Column width="16">
                            <Header as="h3" color="black">
                            Level Up
                            </Header>
                            <div>
                            Earn enough Points to qualify for a higher tier and earn more perks and exclusive benefits!
                            </div>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
            </Grid>

            <Grid
            stackable
            className="component-section-containe rewards-section tier"
            textAlign="center"
            verticalAlign="middle"
            >
              <Grid.Column width="12">
                <h2>PERKS AND BENEFITS</h2>
                <h4>All members earn 2 Points per $1 spent</h4>
                <Grid
                stackable
                columns="3"
                textAlign="center"
                verticalAlign="top"
                >
                  <Grid.Row>
                    <Grid.Column computer="5">
                      <Segment basic className="rewards-tier" padded="very">
                        <h2>Mild Tier</h2>
                        <p>Register and receive 50 Bonus Points</p>
                        <p>$5 Reward every 100 Points you earn</p>
                        <p>Birthday Reward Up to $5 off</p>
                      </Segment>
                    </Grid.Column>
                    <Grid.Column computer="5">
                      <Segment basic className="rewards-tier" padded="very">
                        <h2>Hot Tier</h2>
                        <p>Level up at 200 Points*</p>
                        <p>$5 Reward every 100 Points you earn</p>
                        <p>Birthday Reward Up to $6 off</p>
                        <p>Half-Birthday Reward</p>
                        <p>Quarterly Bonus Points</p>
                      </Segment>
                    </Grid.Column>
                    <Grid.Column computer="5">
                      <Segment basic className="rewards-tier" padded="very">
                        <h2>Eggstra Hot Tier</h2>
                        <p>Level up at 600 Points*</p>
                        <p>$5 Reward every 100 Points you earn</p>
                        <p>Birthday Reward Up to $7 off</p>
                        <p>Half-Birthday Reward</p>
                        <p>Quarterly Bonus Points</p>
                        <p>2x Points every 2nd of the month</p>
                      </Segment>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>

              <Grid.Column width="16">
                <p style={{color:"#000", marginBottom:'1.5em 0', fontSize:'14px'}}>
                *Based on the total number of points you earn in a single year from January 1 - December 31.
                </p>
                <Button color="black" style={{"marginBottom":"1em"}} as="a" href="https://order.eggnbird.com/" target="_blank">ORDER ONLINE</Button>
                <Button  as="a" href="https://eggnbird.myguestaccount.com/guest/accountlogin" target="_blank">LOG IN</Button>
              </Grid.Column>
            </Grid>

            {/* <Grid
            stackable
            className="component-section-containe rewards-section"
            textAlign="center"
            verticalAlign="middle"
            >
              <Grid.Row>
                <Grid.Column width="10">
                  <Grid
                  divide
                  stackable
                  columns="2"
                  textAlign="left"
                  verticalAlign="middle"
                  style={{backgroundColor:"#ededed"}}
                  className="app-img-container"
                  >
                    <Grid.Column>
                      <p>Download our new mobile app!</p>
                      <p>Order, earn and redeem.</p>
                      <div>
                        <img/>
                        <img src="https://www.yogurt-land.com/files/cdn/60402ab42c179.png" size="small" width="135"/>
                        <img src="https://www.yogurt-land.com/files/cdn/60402ab0788fe.png" size="small" width="150"/>
                        </div>
                    </Grid.Column>
                    <Grid.Column>
                      <Image 
                      className="eggnbird-img"
                      src="https://fisherman.gumlet.io/public/eggandbird/63c9923e51e87-removebg-preview.png" 
                      size="small"/>
                    </Grid.Column>
                  </Grid>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width="10" textAlign="left">
                <h6>Got a question? Read the FAQs</h6>
                <h6>Please see Egg N Bird Rewards Terms for complete details.</h6>
                </Grid.Column>
              </Grid.Row>
            </Grid> */}

            <Grid
              stackable
              className="component-section-containe rewards-section apps-container"
              textAlign="center"
              verticalAlign="middle"
              >
                <Grid.Row>
                  <Grid.Column width="10" textAlign="left" only="computer">
                    <div className="app-img-container">
                      <p>Download our new mobile app!</p>
                      <p>Order, earn and redeem.</p>
                      <div>
                       
                        <div>
                        <a href="https://apps.apple.com/us/app/egg-n-bird/id1600572085?uo=2" target="_blank">
                        <img src="https://www.yogurt-land.com/files/cdn/60402ab42c179.png" width="135"/>
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=com.olo.eggnbird" target="_blank">
                        <img src="https://www.yogurt-land.com/files/cdn/60402ab0788fe.png" width="150"/>
                        </a>
                      </div>
                        <div className="tablet desktop">
                          {/* <Image 
                            className="eggnbird-img"
                            src="https://fisherman.gumlet.io/public/eggandbird/63c9923e51e87-removebg-preview.png" 
                            size="medium"
                            responsive
                          /> */}
                          <img 
                          className="eggnbird-img"
                          src="https://fisherman.gumlet.io/public/eggandbird/63c9923e51e87-removebg-preview.png" width="35%"/>
                        </div>
                      </div>
                    </div>
                  </Grid.Column>
                  <Grid.Column width="10" textAlign="center" only="tablet">
                    <div className="app-img-container">
                      <p>Download our new mobile app!</p>
                      <p>Order, earn and redeem.</p>
                      <div>
                        <a href="https://apps.apple.com/us/app/egg-n-bird/id1600572085?uo=2" target="_blank">
                        <img src="https://www.yogurt-land.com/files/cdn/60402ab42c179.png" width="135"/>
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=com.olo.eggnbird" target="_blank">
                        <img src="https://www.yogurt-land.com/files/cdn/60402ab0788fe.png" width="150"/>
                        </a>
                      </div>
                    </div>
                  </Grid.Column>
                  <Grid.Column width="10" textAlign="center" only="mobile">
                    <div className="app-img-container">
                      <p>Download our new mobile app!</p>
                      <p>Order, earn and redeem.</p>
                      <div>
                        <a href="https://apps.apple.com/us/app/egg-n-bird/id1600572085?uo=2" target="_blank">
                        <img src="https://www.yogurt-land.com/files/cdn/60402ab42c179.png" width="135"/>
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=com.olo.eggnbird" target="_blank">
                        <img src="https://www.yogurt-land.com/files/cdn/60402ab0788fe.png" width="150"/>
                        </a>
                      </div>
                    </div>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width="10" textAlign="left">
                    <h6><Link to="/faq" style={{color:"#000"}}>Got a question? Read the FAQs</Link></h6>
                    <h6>
                      <Link to="/terms" style={{color:"#000"}}>Please see Egg N Bird Rewards Terms for complete details.</Link>
                    </h6>
                  </Grid.Column>
                </Grid.Row>
            </Grid>
          </PageLayout>
        </div>
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    allFisherman {
      edges {
        node {
          businessName
          menuImages {
            url
          }
        }
      }
    }
    allFishermanImages {
      edges {
        node {
          galleryImages {
            url
          }
          heroImages {
            url
          }
        }
      }
    }
  }
`;





{/* <Grid
stackable
className="component-section-containr rewards-section"
textAlign="center"
verticalAlign="middle"
>
  <Grid.Column width="10">
    <Header color="black">HOW IT WORKS</Header>
  
    <div style={{background:'#1b1c1d', display:'flex', alignItems:"center"}} className="tablet desktop">
      <div>
        <Image src="https://fisherman.gumlet.io/public/eggandbird/Welcome_to_ENB_Rewards.png" size="large"/>
      </div>
      <div>
      <Segment inverted padded="very">
              <h1>EVERY $1 SPENT = 2 EGGS</h1>
              <p>Register today to start earning delicious rewards!</p>
              <Button>JOIN NOW</Button>
            </Segment>
      </div>
    </div>
    
    <div style={{background:'#1b1c1d',  alignItems:"center"}} className="mobile">
     
      <div>
        <Image src="https://fisherman.gumlet.io/public/eggandbird/Welcome_to_ENB_Rewards.png" size="large"/>
      </div>
      <div>
      <Segment inverted padded="very">
              <h1>EVERY $1 SPENT = 2 EGGS</h1>
              <p>Register today to start earning delicious rewards!</p>
              <Button>JOIN NOW</Button>
            </Segment>
      </div>
    </div>

    <Grid
    divided
    stackable
    columns="3"
    textAlign="center"
    verticalAlign="middle"
    >
      <Grid.Row>
        <Grid.Column>
          <Segment basic>
            <Image src="https://fisherman.gumlet.io/public/eggandbird/63c9a08835df6.png" centered size="tiny"/>
            <h2>Earn 100 eggs, Get $5</h2>
            <p>Each time you earn 100 Eggs, we'll automatically send you a $5 reward</p>
          </Segment>
        </Grid.Column>
        <Grid.Column>
          <Segment basic>
            <Image src="https://fisherman.gumlet.io/public/eggandbird/63c9a08cb33aa.png" centered size="tiny"/>
            <h2>Birthday Treat</h2>
            <p>Let us help you celebrate. Enjoy a FREE birthday treat — on us!</p>
          </Segment>
        </Grid.Column>
        <Grid.Column>
          <Segment basic>
            <Image src="https://fisherman.gumlet.io/public/eggandbird/63c9a090671f2.png" centered size="tiny"/>
            <h2>Level Up</h2>
            <p>Earn enough Eggs to qualify for a higher tier and earn more perks and exclusive benefits!</p>
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Grid.Column>
</Grid> */}
